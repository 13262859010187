import axios from "axios";
import NProgress from 'nprogress'

const service = axios.create({
    baseURL: 'https://api.meta-utopia.info/index',
    timeout: 60000
})

service.interceptors.request.use(config => {
    NProgress.start()
    return config
})

service.interceptors.response.use(res => {
    NProgress.done()
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
        return res.data
    }
    if (code !== 200) {
        Notification.error({
            title: msg
        })
        return Promise.reject('error')
    } else {
        return res.data
    }
},
error => {
    return Promise.reject(error)
}
)

export default service