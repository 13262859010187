import request from '@/utils/request'

// join
export function submitEmail(data) {
    return request({
        url: '/content/saveEmail',
        method: 'post',
        data
    })
}

// cities
export function getCityList(params) {
    return request({
        url: '/content/getNftCityList',
        method: 'get',
        params
    })
}